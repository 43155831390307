<div class="max-w-lg">
	<fieldset class="border-2 border-gfx-primary rounded-lg p-2">
		<legend class="font-bold p-2">Subscription Status</legend>
		@if (!loading$()) {
			<div>Subscriptions are managed via the Group Fitness X app.</div>
			<div class="grid grid-cols-2">
				<div class="font-bold">Subscription Status:</div>
				<div>{{ getSubscriptionStatus() }}</div>
				@if (nextBillingCycleDate != '') {
					<div class="font-bold">Next Billing Date:</div>
					<div>{{ nextBillingCycleDate }}</div>
				}

				@if (trialEndDate !== '') {
					<div class="font-bold">Trial End Date:</div>
					<div>{{ trialEndDate }}</div>
				}

				<!-- <div class="font-bold">Billing Cycle:</div>
				<div class="flex items-center gap-4">
					<div>{{ billingPeriodUnit }}</div>
					<a
						class="text-xs"
						(click)="showManageSubscriptionDialog(billingPeriodUnit === 'Monthly' ? 'Yearly' : 'Monthly')"
						>Change to {{ billingPeriodUnit === 'Monthly' ? 'Yearly' : 'Monthly' }}</a
					>
				</div> -->
			</div>

			@if (hasActiveTrialSubscription$()) {
				<div class="max-w-lg mx-auto p-4 mb-4 rounded-lg shadow-md border-2 bg-gfx-warning-panel text-black">
					<span
						>You have a trial subscription that expires on {{ this.trialEndDate }}. You can view subscription benefits
						and subscribe <a [href]="marketingSiteUrl + '/pricing'" class="text-gfx-primary underline">here</a>.</span
					>
				</div>
			} @else if (subscriptionWillExpire$()) {
				<div class="max-w-lg mx-auto p-4 mb-4 rounded-lg shadow-md border-2 bg-gfx-warning-panel text-black">
					<mat-icon color="warn">warning</mat-icon>
					<span class="ml-2"
						>Your subscription will expire on {{ this.nextBillingCycleDate }}. Subscriptions are managed via the Group
						Fitness X mobile app.</span
					>
				</div>
			} @else if (subscriptionHasExpired$()) {
				<div class="max-w-lg mx-auto p-4 mb-4 rounded-lg shadow-md border-2 bg-gfx-warning-panel text-black">
					<mat-icon color="warn">warning</mat-icon>
					@if (this.nextBillingCycleDate) {
						<span class="ml-2"
							>Your subscription expired on {{ this.nextBillingCycleDate }}. Subscriptions are managed via the Group
							Fitness X mobile app.</span
						>
					} @else {
						<span class="ml-2"
							>Your subscription has expired. Subscriptions are managed via the Group Fitness X mobile app.</span
						>
					}
				</div>
			} @else if (!hasActiveSubscription$()) {
				<div class="max-w-lg mx-auto p-4 mb-4 rounded-lg shadow-md border-2 bg-gfx-warning-panel text-black">
					<mat-icon color="warn">warning</mat-icon>
					<span class="ml-2"
						>You do not have an active subscription. Subscriptions are managed via the Group Fitness X mobile app.</span
					>
				</div>
			}

			<!-- @if (hasActiveSubscription$()) {
				<button mat-flat-button class="gfx-button-primary mt-4" (click)="showManageSubscriptionDialog()">
					Manage Subscription
				</button>
			} -->

			@if (cancelMessage$()) {
				<div>
					<p>{{ cancelMessage$() }}</p>
				</div>
			}
		}
	</fieldset>
</div>
